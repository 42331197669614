<div class="modal-header erp-splash-header">
    <span class="cbds-c-clipboard cbds-c-iconButton--contained" aria-label="clipboard">
        <svg class="cbds-c-clipboard clipboard-icon" focusable="false">
            <use [attr.href]="assetURL+'assets/icons/cbds-icons-ui.svg#clipboard'" [attr.xlink:href]="assetURL+'assets/icons/cbds-icons-ui.svg#clipboard'"></use>
        </svg>
    </span>
    <h4 class="modal-title fraud-protect">In order to provide you with new bank integration capabilities,
        please tell us what ERP or accounting software your company is using, or planning to use:</h4>
</div>
<div class="modal-body erp-splash-body justify-content-center">
    <form (ngSubmit)="onSubmit()" [formGroup]="form" [ngClass]="{ 'was-validated': submitted }" novalidate>
        <div class="form modal-content update-modal">
            <div class="form-row erp-splash">
                <div class="col-md-auto col-sm-auto">
                    <select formControlName="selectedValue" id="dropdown">
                        <option value="" disabled selected>Click here to select</option>
                        <option *ngFor="let value of dropdownValues" [value]="value">{{ value }}</option>
                    </select>
                    <div class="other-value form-row" *ngIf="f.selectedValue.value === 'Other'" [ngClass]="{ 'has-error': f.submitted  && f.otherValue.errors }">
                        <div class="col-xl-3 col-md-4 col-sm-6 other-value">
                            <label class="other-text" for="otherValue">Please enter your ERP or Accounting Software below:</label>
                            <input type="text" class="form-control companyID" formControlName="otherValue"
                                id="otherValue" name="otherValue" autocomplete="off" maxlength="50" tabindex="-2"
                                required appTrimInput>
                        </div>
                    </div>
                </div>
            </div>
            <div class="buttonArea erp-splash">
                <button aria-label="Submit" class="btn btn-primary" id="btnSave" name="btnSave" tabindex="-1"
                    type="submit" [disabled]="form.invalid">Submit</button>
            </div>
        </div>
    </form>
</div>
