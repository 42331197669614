import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { DashboardService } from 'src/app/service/dashboard/dashboard.service';
import { assetURL, environment } from 'src/environments/environment';

@Component({
  selector: 'app-erp-connect-info',
  templateUrl: './erp-connect-info.component.html',
  styleUrls: ['./erp-connect-info.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ErpConnectInfoComponent implements OnInit {
  @Input() modalRef: NgbModalRef;
  public contactMessage: any = environment.contactMessage;
  erpInfo: string = '';
  learnMoreUrl: string;
  expressedInterest: boolean = false;
  acknowledged: boolean = false;
  showModal: boolean = true;
  erpConnectInfoNetSuiteVideo: string;
  erpConnectInfoIntacctVideo: string;
  erpConnectInfoMSDynamicsVideo: string;

  constructor(private dashboardService: DashboardService, private modal: NgbModal){}

  get assetURL() {
    return assetURL;
  }

  ngOnInit(): void {
    const ack = sessionStorage.getItem('ack');
    let mergedAck;
    if (ack) {
      mergedAck = JSON.parse(ack)
      this.erpInfo = mergedAck.erpInfo
    }
    this.dashboardService.getErpInfoConnectUrlsFromCDN().subscribe((res) => {
      this.learnMoreUrl = res.erpConnectInfoLearnMoreUrl;
      this.erpConnectInfoNetSuiteVideo = res.erpConnectInfoNetSuiteVideo;
      this.erpConnectInfoIntacctVideo = res.erpConnectInfoIntacctVideo;
      this.erpConnectInfoMSDynamicsVideo = res.erpConnectInfoMSDynamicsVideo;
    })
  }

  openVideo() {
    if (this.erpInfo === 'NetSuite') {
      window.open(this.erpConnectInfoNetSuiteVideo, '_blank');
    } else if (this.erpInfo === 'Sage Intacct') {
      window.open(this.erpConnectInfoIntacctVideo, '_blank');
    } else {
      window.open(this.erpConnectInfoMSDynamicsVideo, '_blank');
    }
    this.setInterest();
  }

  setInterest() {
    this.expressedInterest = true;
  }

  cross() {
    this.acknowledged = true;
    const payload = {
      'acknowledged': this.acknowledged,
      'expressedInterest': this.expressedInterest
    }
    console.log(payload);
    this.dashboardService.saveErpConnectInfo(payload);
    this.modalRef.close();
  }


}
