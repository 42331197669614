import { Component, ViewEncapsulation} from '@angular/core';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Const } from 'src/app/core/enums/const.enum';
import { DashboardService } from 'src/app/service/dashboard/dashboard.service';
import { assetURL } from 'src/environments/environment';

@Component({
  selector: 'app-erp-splash',
  templateUrl: './erp-splash.component.html',
  styleUrls: ['./erp-splash.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ErpSplashComponent {
  selectedValue: string;
  dropdownValues: string[] = Const.erpDropdownValues
  form: FormGroup;
  submitted: boolean = false;

  get assetURL() {
    return assetURL;
  }

  constructor(private readonly fb: UntypedFormBuilder,
              private service: DashboardService
  ) {
    this.createForm();
  }


  createForm() {
    this.form = this.fb.group({
      selectedValue: ['', Validators.required],
      otherValue: ['']
    });

    this.form.get('selectedValue').valueChanges.subscribe(value => {
      if (value === 'Other') {
        this.form.get('otherValue').setValidators([Validators.required]);
      } else {
        this.form.get('otherValue').clearValidators();
      }
      this.form.get('otherValue').updateValueAndValidity();
    });
  }

  get f() {
    return this.form.controls;
  }



  onSubmit() {
    this.submitted = true;
    if (this.form.invalid) {
      console.log('Form is invalid');
      return;
    } else {
      const selectedValue = this.form.value.selectedValue;
      const otherValue = this.form.value.otherValue;
      if (selectedValue === 'Other') {
        this.service.saveErpInfo(otherValue);
      } else {
        this.service.saveErpInfo(selectedValue);
      }
      
    }
  }

}
