import { AfterViewInit, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { environment } from '../environments/environment';
import { Title } from '@angular/platform-browser';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { datadogRum } from '@datadog/browser-rum';
import { ApplicationService } from './core/service/application.service';
import { TabService } from './core/service/tab.service';
import { datadogLogs } from '@datadog/browser-logs';
import { UniversalSessionService } from './core/service/universalSession.service';
import { navigationState } from '@citizens/mfe-shared-state';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, AfterViewInit {
  public title: string = environment.siteName;

  private alive = environment.univ_alive;
  private inactiveInterval = this.alive.inactiveInterval || 15;  // defaults to 15 second
  private aliveInterval = this.alive.idleTimeout || 1200;        // defaults to 20 minutes
  private previousUrl = '';
  private appName: string;

  constructor(private titleService: Title,
              private router: Router,
              private appService: ApplicationService,
              private univSessionService: UniversalSessionService,
              private tabService: TabService,
              private idle: Idle,
              keepalive: Keepalive,
              cd: ChangeDetectorRef) {
    this.titleService.setTitle(this.title);

    router.events.pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        console.log('prev:', event.url);
        this.previousUrl = event.url;

        // looking for beta/gamma application name for logout
        // this code can be removed when beta/gamma code is removed
        if (this.previousUrl.startsWith('/login', 0)) {
          this.appName = this.previousUrl.replace('/login', '');
        }
      });

    // set idle timeout parameters
    idle.setIdle(this.inactiveInterval);
    idle.setTimeout(this.aliveInterval);
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    // do something when the user is no longer idle
    idle.onIdleEnd.subscribe(() => {
      cd.detectChanges();
    });

    idle.onTimeout.subscribe(() => {
      sessionStorage.removeItem('banner_preference');
      sessionStorage.clear();
      localStorage.clear()
      this.router.navigate(['uportal/logout/universal']);
      this.univSessionService.logout();
    });

    keepalive.interval(5);
    
    this.appService.init()
    // singleton init once
    this.tabService.init();
  }

  ngOnInit(): void {
    console.log("V2.17.5")
    datadogRum.init({
          applicationId: 'd01627fd-3482-42f7-ab68-b8ead3e63c02',
          clientToken: 'pub1629f01f4765ddac1a5a2cb1bc800d08',
          site: 'datadoghq.com',
          service: 'sso-client-',
          env: environment.env,
          // Specify a version number to identify the deployed version of your application in Datadog
          // version: '1.0.0',
          sampleRate: 100,
          trackInteractions: true,
          defaultPrivacyLevel: 'mask-user-input'
        });
    datadogRum.startSessionReplayRecording();
    datadogLogs.init({
      clientToken: 'pub1629f01f4765ddac1a5a2cb1bc800d08',
      site: 'datadoghq.com',
      forwardErrorsToLogs: true,
      sampleRate: 100,
      env: environment.env ,
      service: 'commercial-sso-client',
      forwardConsoleLogs: ['error']
    })
    this.reset();

    // start listening to routing from in-browser module
    try {
      navigationState.getNavigation().subscribe((newRoute: any) => {
        this.router.navigateByUrl(newRoute);
      });
    } catch (err) {
      console.log("Unable to consume shared state", { err });
    }
  }

  reset() {
    // we'll call this method when we want to start/reset the idle process
    // reset any component state and be sure to call idle.watch()
    this.idle.watch();
  }

  ngAfterViewInit(): void {
    const readyEvent = new CustomEvent("citizens:mfe-ready", {
      detail: {
        mfeName: "sample-mfe-1"
      }
    });
    window.dispatchEvent(readyEvent);
  }

}
