export class Const {

    // config
    public static readonly OUDAppNames = [ 'accessoptima', 'achieve', 'trade360', 'seeburger', 'CASHFLOW' ]
    public static readonly fraudProtectionUrl = 'https://www.citizensbank.com/corporate-finance/capability/treasury-management/fraud-protection.aspx?ctzMode=CFG-B2B_CGW_CMC_CTZ_CYBER_CYBERAO';


    public static readonly fraudDisplayData = [
        {
            title: "Cellphone and/or landline in your Profile",
            type: "phoneRegistered",
            toggleOpen: false,
            title_desc: "Your profile has cellphone and/or landline to receive OTP via text or voice",
            title_desc_neg: "Your profile doesn't have cellphone and/or landline to receive OTP via text or voice",
            description: "Text and voice are more secure than email as One-Time-Passcode (OTP) delivery methods.\
          Email accounts are the biggest target of fraudsters and most cyberattacks infiltrate an organization via email.\
          You can add cellphone and/or landline to your Universal profile by using 'Update Profile' feature.\
          Training video is available in following webpage to show you how to use 'Update Profile' feature.\
          <a href='https://client.citizensbank.com/s/knowledgearticledetail?type=UniversalLogin' target='_blank'>https://client.citizensbank.com/s/knowledgearticledetail?type=UniversalLogin</a>"
        },
        {
            title: "Dual Approval for Wire",
            type: "dualApprovalForWire",
            toggleOpen: false,
            title_desc: "Your business has dual control for wire payments",
            title_desc_neg: "Your business doesn't have dual control for wire payments",
            description: "As a default in accessOPTIMA, payments are set up for dual control – one user initiates a payment and a second user approves it. \
            The system provides the ability to set up a tiered approval workflow with a maximum of six approvals for ACH, Loan, RTP and Wire payments as \
            well as account-to-account transfers. If your business is not enroll in dual control for payments, please contact us for more \
            information <a href='https://client.citizensbank.com/s/findsupport' target='_blank'>https://client.citizensbank.com/s/findsupport</a>. "
        },
        {
            title: "Dual Approval for ACH",
            type: "dualApprovalForAch",
            toggleOpen: false,
            title_desc: "Your business has dual control for ACH payments",
            title_desc_neg: "Your business doesn't have dual control for ACH payments",
            description: "As a default in accessOPTIMA, payments are set up for dual control – one user initiates a payment and a second user approves it.\
             The system provides the ability to set up a tiered approval workflow with a maximum of six approvals for ACH, Loan, RTP and Wire payments as \
             well as account-to-account transfers. If your business is not enroll in dual control for payments, please contact us for more \
             information <a href='https://client.citizensbank.com/s/findsupport' target='_blank'>https://client.citizensbank.com/s/findsupport</a>. "
        },
        {
            title: "Dual Approval for RTP",
            type: "dualApprovalForRtp",
            toggleOpen: false,
            title_desc: "Your business has dual control for RTP payments",
            title_desc_neg: "Your business doesn't have dual control for RTP payments",
            description: "As a default in accessOPTIMA, payments are set up for dual control – one user initiates a payment and a second user approves it. \
            The system provides the ability to set up a tiered approval workflow with a maximum of six approvals for ACH, Loan, RTP and Wire payments as \
            well as account-to-account transfers. If your business is not enroll in dual control for payments, please contact us for more \
            information <a href='https://client.citizensbank.com/s/findsupport' target='_blank'>https://client.citizensbank.com/s/findsupport</a>. "
        },
        {
            title: "Alerts",
            type: "aoAlerts",
            toggleOpen: false,
            title_desc: "Your business is enrolled with the accessOPTIMA alert service",
            title_desc_neg: "Your business is not enrolled with the accessOPTIMA alert service",
            description: "accessOPTIMA’s robust alerts functionality keeps you informed and allows you to execute and approve transactions on any \
            internet-connected device. A variety of alerts allow you to monitor accounts and help you avoid possible fraud.  If you are currently \
            enrolled with the accessOPTIMA alert service, take advantage of the various alerts available to you. For more information, go \
            to the Knowledge Center <a href='https://client.citizensbank.com/s/knowledgecenter' target='_blank'>https://client.citizensbank.com/s/knowledgecenter</a>."
        },
        {
            title: "Payment Limits",
            type: "hasLimits",
            toggleOpen: false,
            title_desc: "Your business sets approval limits in accessOPTIMA",
            title_desc_neg: "Your business does not set approval limits in accessOPTIMA",
            description: "Approval limits in accessOPTIMA allow you to set user approval limits for payments and risk management features either \
            per account or overall.  Account approval limits can be set at transaction level, ACH batch or a daily limit for a given Value Date. \
            Overall approval limits allow you to indicate the maximum amount of payments or risk management items with the same Value Date that \
            a user can approve for a specific payment/item type.  For more information on approval limits,  go to the Knowledge \
            Center <a href='https://client.citizensbank.com/s/knowledgecenter' target='_blank'>https://client.citizensbank.com/s/knowledgecenter</a>."
        },
        {
            title: "Image Services",
            type: "imageServices",
            toggleOpen: false,
            title_desc: "Your business is enrolled with image services",
            title_desc_neg: "Your business is not enrolled with accessOPTIMA Image Search",
            description: "accessOPTIMA Image Search feature allows you to view images, next business day, for paid checks, deposit tickets, Items \
            deposited and  returned deposit items. Controlled disbursement check images are available after the day's final presentment. Access \
            images up to 7 years to facilitate cash flow, account reconciliation and mitigate fraud."
        },
        {
            title: "Positive Pay",
            type: "positivePay",
            toggleOpen: false,
            title_desc: "Your business is enrolled with Positive Pay",
            title_desc_neg: "Your business is not enrolled with Positive Pay",
            description: "Citizens helps give you the upper hand in countering and mitigating fraud with services like our ARP offerings. While \
            the electronic exchange of funds continues to increase, a large percentage of business-to-business payments are still made by check, \
            making it important for you to protect yourself. The fact is that you are likely to encounter attempts at fraud, and Positive Pay and \
            Reverse Positive Pay are among the top choices for countering fraudulent activity.  Positive Pay provides you  protection against \
            check fraud by comparing any checks that post to your account to the issue records previously submitted to the bank and alerting you of any mismatches.\
            <ul>\
            <li>Alerts you to any checks that have posted to your  account that don’t match issued check records</li>\
            <li>Allows you to return fraudulent/erroneous checks within the deadline without the need for an affidavit or fraud investigation</li>\
            <li>Funds for returned checks are credited back the next day</li>\
            </ul>"
        },
        {
            title: "ACHieve Access",
            type: "achieveAccess",
            toggleOpen: false,
            title_desc: "Your business is enrolled with ACH Positive Pay",
            title_desc_neg: "Your business is not enrolled with ACH Positive Pay",
            description: "ACHieve Access®\
          ACH transactions reduce costs, speed up procurement processes and eliminate paperwork. But the ease and power of ACH can create the risk of unauthorized transactions and fraudulent debits.         ACHieve Access helps treasurers execute successful cash management strategies, manage the growing volume of ACH payments and efficiently process transactions, while mitigating fraud. \
          Take greater control over ACH transactions and safeguard your accounts against fraud with ACHieve Access®. This powerful online tool enables you to: \
          <ul>\
          <li>Maintain control over ACH debits and credits posting to your account(s)</li>\
          <li>Designate specific dollar amounts and the maximum number of transactions allowed to post from a specific originator</li>\
          <li>Indicate which incoming ACH transactions can post and those that should be blocked</li>\
          <li>Utilize the dual verification option that requires two authorized users for ACH Positive Pay decisioning</li>\
          <li>Access up to 40 days of ACH transactions to resolve inquiries on received or originated items</li>\
          <li>Receive email notifications on pending issues, such as unauthorized incoming debits or unprocessed origination files</li>\
          </ul>\
          ACHieve Access® is compatible with our online accessOPTIMA® application and is often used in conjunction with our Cash Concentration service."
        }
    ];

    public static readonly digitizedPaymentKnowMoreLink = "https://www.citizensbank.com/corporate-finance/capability/treasury-management/making-payments.aspx";

    public static readonly sprinklrEmbededUrl = 'https://gallery.sprinklr.com/clients/4698/embeds/ZW1iZWQ6NjUzZmYxNmY2OGI5NjE0ZmU0NDUwOTVi/script.js';

    public static readonly sprinklrEmbededId = 'sprinklr-embed-loader-4698-ZW1iZWQ6NjUzZmYxNmY2OGI5NjE0ZmU0NDUwOTVi';

    public static readonly sprinklrEmbededType = 'text/javascript';

    public static readonly commercialTwitterUrl = 'https://twitter.com/CFGCommercial';

    public static readonly commercialLinkedInUrl = 'https://www.linkedin.com/company/citizenscommercialbanking/';

    public static readonly commercialYouTubeUrl = 'https://www.youtube.com/@citizenscommercialbanking4424/featured';

    public static readonly shortDisplayNames = {
        "accessOPTIMA": "accessOPTIMA",
        "ACHieve Access": "ACHieve Access",
        "accessGLOBAL Trade Flow": "Global Trade",
        "Citizens File Gateway": "File Gateway",
        "Cash Flow Essentials": "Cash Flow Essentials",
        "Carbon Management Portal": "Carbon Mgmt.",
        "Agency Escrow Portal": "Agency Escrow",
        "Global Markets Client Portal": "Global Markets",
        "Citizens AssetFinanceConnect": "Asset Finance",
        "Partner Loan Program": "Partner Loan",
        "Coin and Currency": "Coin&Currency",
        "Treasury Management Platform": "Treasury Mgmt."
    }

    public static readonly butlerImageUrl = 'assets/lady_with_glasses.jpg';

    public static readonly erpDropdownValues = [
        'SAP S/4HANA',
        'SAP (other version)',
        'NetSuite',
        'Sage Intacct',
        'Sage (other version)',
        'Microsoft Dynamics Business Central',
        'Microsoft Dynamics (other version)',
        'Xero',
        'QuickBooks Online',
        'QuickBooks Desktop',
        'Acumatica',
        'FreshBooks',
        'Infor',
        'Workday',
        'Oracle',
        'JD Edwards',
        'Other',
        'I don\'t know / Not Applicable'
    ];

    public static readonly erpConnectInfoLearnMoreUrl = 'https://client.citizensbank.com/s/knowledgearticledetail?type=ERPConnect';

    public static readonly erpConnectInfoNetSuiteVideo = 'https://p.allego.com/E4NUbFEH3YxQ3';

    public static readonly erpConnectInfoIntacctVideo = 'https://p.allego.com/KEji1mqJRlevToK';

    public static readonly erpConnectInfoMSDynamicsVideo = 'https://p.allego.com/KEji1mqJRlevT8K';
}
