<div class="modal-header">

    <h4 class="modal-title fraud-protect">Do you know that you can bank with Citizens directly from your {{erpInfo}}?</h4>
    <span class="cbds-c-iconButton remove-popup cbds-c-iconButton--contained cbensightenevent" aria-label="close"
        cbdata-type="link" cbdata-reason="splash-modal-close-cross" (click)="cross()">
        <svg class="cbds-c-icon close-icon col-md-2" focusable="false">
            <use [attr.href]="assetURL+'assets/icons/cbds-icons-ui.svg#close'"
                [attr.xlink:href]="assetURL+'assets/icons/cbds-icons-ui.svg#close'"></use>
        </svg>
    </span>
</div>
<div class="modal-body justify-content-center">
    <p class="erp-connect-text">
        ERPConnect brings your Citizens treasury management and payment functions into your {{erpInfo}} to help your
        account payables and reconciliation processes get more efficient. <br />

        Pay your invoices and automate the writing of accounting entries. Get bank data feeds into your reconciliation
        module, save time, reduce encoding errors, and increase visibility and control. <br />

    </p>
    <p class="learn-more-text">Learn more 
        <a class="cbensightenevent" cbdata-type="link" cbdata-reason="erp-connect-info-email" target="_blank" href="{{learnMoreUrl}}" (click)="setInterest()">here</a>
        or contact us: <a class="cbensightenevent" cbdata-type="link" cbdata-reason="erp-connect-info-email"
        target="_blank" href="mailto:{{ contactMessage.email }}?subject=I%20am%20interested%20in%20ERPConnect" (click)="setInterest()">{{ contactMessage.email }}</a>
    </p>
    <div class="buttonAreaSplash">
        <button class="btn btn-primary" name="btnClose" id="btnClose" type="submit" tabindex="1"
        value="Watch a Quick Video" aria-label="Watch a Quick Video" (click)="openVideo()"> Watch a Quick Video </button>
        <button class="btn btn-primary" name="btnClose" id="btnClose" type="submit" tabindex="1"
        value="Close" aria-label="Close" (click)="cross()"> Close </button>
      </div>
</div>