<footer class="footer" *ngIf="!butlerApp">
  <hr *ngIf="isLoginRoute && isCreds !== 'otpFlow'">
  <div class="footer-top-section" *ngIf="isLoginRoute && isCreds !== 'otpFlow'">
    <div class="container footer-content">
      <div class="row">
        <div class="col-md-6 col-12">
          <div class="footer-logo"></div>
        </div>
        <div class="footer-company col-md-3 col-6">
          <span class="footer-heading">Company</span>
          <ul class="footer-top-links" *ngFor="let item of footerCompanyLinks">
            <li [tabIndex]="item" role="listitem" (click)="selectFooterLink(item.url)" (touchend)="selectFooterLink(item.url)">{{item.name}}</li>
          </ul>
        </div>
        <div class="footer-resources col-md-3 col-6">
          <span class="footer-heading">Resources</span>
          <ul class="footer-top-links" *ngFor="let item of footerResourcesLinks">
            <li [tabIndex]="item" role="listitem" (click)="selectFooterLink(item.url)" (touchend)="selectFooterLink(item.url)">{{item.name}}</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="footer-bottom-section" [ngClass]="{'margin-check': !isLoginRoute}">
    <div class="container footer-bottom">
      <div class="row">
        <div class="col-md-12 col-12 footer-area">
          <span class="footerItem" *ngFor="let item of footerBottomLinks">
            <a rel="noopener noreferrer nofollow" target="_blank" href="{{ item.url }}">{{ item.name }}</a>
          </span>
          <div id="disclaimer" class="disclaimer" *ngIf="isSMBUser">
            {{ disclaimer }}
          </div>

          <div class="footer-items">
            <div class="top-row">
              <span *ngFor="let item of footerBottomFirstLinks; let i = index">
                <a target="_blank" [href]="item.url">{{ item.name }}</a>
                <span *ngIf="i < footerBottomFirstLinks.length - 1"> | </span>
              </span>
            </div>
            <div class="bottom-row">
              <span *ngFor="let item of footerBottomSecondLinks; let i = index">
                <a target="_blank" [href]="item.url">{{ item.name }}</a>
                <span *ngIf="i < footerBottomSecondLinks.length - 1"> | </span>
              </span>
            </div>
            <div id="disclaimer" class="disclaimer" *ngIf="isSMBUser">
              {{ disclaimer }}
            </div>
          </div>
          
        </div>
      </div>
    </div>
  </div>
</footer>
